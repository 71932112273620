/*
 * IMPORTS
 */
import React, {Component} from "react";
import {Link} from 'react-router-dom'


/*
 * Class
 */
class Footer extends Component {
    render() {
        return (
            <React.Fragment>

             {/* Start  CTA Area */}
            <section class="cta-section text-center _bg-primary p-5 _mt-5 bg_cta text-white">
                <div class="container">
                    <h2 class="cta_heading">Get in Touch </h2>
                    <p>Have a question or need directions? We’re here to help you get to the right place.</p>
                    <a href="/contact" class="btn btn-primary cta-btn">Contact Us</a>
                </div>
            </section>
             {/* End  CTA Area */}

                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-12">
                                <div className="footer-right" data-black-overlay="6">
                                        <Link className="rn-button-style--2 mb-5" to="/contact">
                                            <span>Contact Us</span>
                                        </Link>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="copyright-text">
                                                <div className='h4 font-weight-light text-white'>Corporate / Sales &
                                                    Marketing Office :
                                                </div>
                                                <p>BMI Cables Private Limited <br/>
                                                    406, Bhikaji Cama Bhawan,
                                                    Bhikaji Cama Place,<br/>
                                                    New Delhi-110066</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-2">
                                            <div className="copyright-text">
                                                <div className='h4 font-weight-light text-white'>Factory :
                                                </div>
                                                <p>BMI Cables Private Limited<br/>
                                                    F- 81 to 84, Kushkhera, RIICO
                                                    Industrial Area, Bhiwadi,<br/> 
                                                    Distt. Alwar, Rajasthan-301019 </p>
                                            </div>
                                        </div>
                                        <div className='developed'>
                                            <p>Developed By @ <a rel="noopener noreferrer" target='_blank' style={{'color': '#ca3c08'}}
                                                                    href='https://rootandleaves.com/'
                                                                    >RootandLeaves</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>


            </React.Fragment>


        )
    }
}

/*
 * EXPORTS
 */
export default Footer;
