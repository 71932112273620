let data = [
    {
        images: '4',
        title: 'VIRENDER PANDITA',
        designation: 'AGM - BD'
    },   
    {
        images: '2',
        title: 'SURESH BAUNTHIYAL',
        designation: 'VP - Marketing & Sales',
    },
    {
        images: '5',
        title: 'Anuradha Kakkar',
        designation: 'AGM - Sales'
    },
    
    {
        images: '6',
        title: 'Sunil Gupta',
        designation: 'AGM - BD',
    },

];

export default data;
