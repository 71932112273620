/*
 * IMPORTS
 */
import React from "react";

import ScrollToTop from "react-scroll-up";
import { FiCheck, FiChevronUp } from "react-icons/fi";
import { Link } from "react-router-dom";
import { MdHighQuality, MdVerifiedUser } from "react-icons/md";
import { GiStarsStack } from "react-icons/gi";
import { FaHandshake } from "react-icons/fa";
import { GiWallet } from "react-icons/gi";
import { FaShippingFast } from "react-icons/fa";



import "../../public/assets/css/custom_style.css";

import Slider from 'react-slick';
import { MdArrowForward, MdArrowBack } from 'react-icons/md';

/*
 * COMPONENTS
 */
import HeaderThree from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import PortfolioList from "../elements/portfolio/PortfolioList";
import Helmet from "../component/common/Helmet";

/*
 * Const assignment
 */
// const SlideList = [
//   {
//     textPosition: "text-left",
//     category: "Innovative Cable Solutions",
//     title: "Cable <span>Connections</span> That Lasts.",
//     description: "",
//     buttonText: "",
//     buttonLink: "",
//   },
// ];

const SlideList = [
    {
      image: "/assets/images/banner/banner1.jpg",
      title1: " Leading Manufacturers of Cables",
      buttonText: "Explore More",
      buttonLink: "/about",
      //description: "Innovative Cable Solutions", // Optional description
    },
    {
      image: "/assets/images/banner/banner2.jpg",
      title1: "Experience Seamless Connectivity",
      buttonText: "Explore More",
      buttonLink: "/about"
      //description: "Long-lasting, high-quality cables.", // Optional description
    },
    {
      image: "/assets/images/banner/banner3.jpg",
      title1: "Cable Connections That Lasts.",
      buttonText: "Explore More",
      buttonLink: "/about"
      //description: "Cutting-edge cable technology.", // Optional description
    },
  ];

// const SlideList = [
//     {
//       image: "/assets/images/homepage-images/1.webp",
//       title: "Cable Connections That Last",
//       description: "Innovative Cable Solutions",
//     },
//     {
//       image: "/assets/images/homepage-images/2.webp",
//       title: "Reliable & Durable",
//       description: "Long-lasting, high-quality cables.",
//     },
//     {
//       image: "/assets/images/homepage-images/3.webp",
//       title: "Advanced Technology",
//       description: "Cutting-edge cable technology.",
//     },
//   ];
  


const whyChooseUsContent = [
  {
    icon: <MdHighQuality size={40} />,
    title: "EXTENSIVE PRODUCT RANGE",
    description: "We have extensive product range of LV/MV, PVC/ XLPE Power.",
  },
  {
    icon: <GiStarsStack size={40} />,
    title: "QUALITY",
    description:
      "Customers will choose a product that meets their expectation in terms.",
  },
  {
    icon: <FaHandshake size={40} />,
    title: "RELIABLITY",
    description: "At BMI our customers feel confident in their investment.",
  },
  {
    icon: <MdVerifiedUser size={40} />,
    title: "CUSTOMER SERVICE",
    description:
      "Customer Service in a crucial aspect of any successful business.",
  },
  {
    icon: <GiWallet size={40} />,
    title: "AFFORDABILITY",
    description:
      "Affordability is critical aspect that customers consider when purchasing.",
  },
  {
    icon: <FaShippingFast size={40} />,
    title: "DELIVERY",
    description:
      "Today Customers value in time delivery of their orders and BMI.",
  },
];

/*
 * OBJECT
 */
const PortfolioLanding = () => {
 // Carousel settings for react-slick
    const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <MdArrowBack />,
    nextArrow: <MdArrowForward />,
    fade: true, // Enable fade effect
  }
  
  // Let and Const Assignment
  const PostList = [
    {
      images: "01",
      title: "Cables for the Oil and Gas Industry",
      category: "Oil & Gas",
      image: "/assets/images/focus/oil.webp",
    },
    {
      images: "02",
      title: "Cables for the Steel and Power Industry",
      category: "Steel and Power",
      image: "/assets/images/focus/steel.png",
    },
    {
      images: "03",
      title: "Cables for the Auto Industry",
      category: "Automotive",
      image: "/assets/images/focus/automative.webp",
    },
    {
      images: "04",
      title: "Cables for the Railways",
      category: "Railways",
      image: "/assets/images/focus/rail.png",
    },
    {
      images: "05",
      title: "Cables for the Appliances",
      category: "Appliances",
      image: "/assets/images/focus/appliance.png",
    },
    {
      images: "06",
      title: "Cables for the Agriculture",
      category: "Agriculture",
      image: "/assets/images/focus/factory.png",
    },
    {
      images: "07",
      title: "Cables for the Telecom",
      category: "Telecom",
      image: "/assets/images/focus/tele.png",
    },
    {
      images: "08",
      title: "Cables for the Renewable Energy",
      category: "Renewable Energy",
      image: "/assets/images/focus/renewable.png",
    },
  ];


  const namesItemOne = [
    "BMI is a leading manufacturer of Electrical Cables and Wires, having corporate office in New Delhi and manufacturing unit in Bhiwadi, Rajasthan and is group company of ESS ELL cable group.",
    "BMI is ISO 9001:2015 certified company, with advanced production lines and quality control facilities. Our integrated plant produces high-quality cables meeting global standards. We also provide customized cables to meet specific customer requirements.",
    "The quality control program of BMI Cables includes a well-equipped Testing Laboratory to test incoming and finished products at every stage. Our products have passed rigorous in-house and third-party testing.",
    "BMI is one of the leading manufacturers of specialty cables and trusted by industries such as Oil & Gas, Steel & Power, Automotive, Telecome, Agriculture and Home Appliances.",
  ];

  // Return
  return (
    <div>
      {/* <Helmet pageTitle="BMI Cables" />
      <HeaderThree home homeLink="/" logo="symbol-light" color="color-black" /> */}
      {/* Start Slider Area Old  */}
      {/* <div>
        <div className="slider-wrapper">
          
          {SlideList.map((value, index) => (
            <div
              className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center"
              key={index}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? (
                        <span style={{ height: "20px" }}></span>
                      ) : (
                        ""
                      )}
                      {value.title ? (
                        <h1
                          className="title"
                          dangerouslySetInnerHTML={{ __html: value.title }}
                        ></h1>
                      ) : (
                        ""
                      )}
                      {value.description ? (
                        <p className="description">{value.description}</p>
                      ) : (
                        ""
                      )}
                      {value.buttonText ? (
                        <div className="slide-btn">
                          <a
                            className="rn-button-style--2 btn-primary-color"
                            href={`${value.buttonLink}`}
                          >
                            {value.buttonText}
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="hexagonal__wrapper">
                <div className="hexagonal one">
                  <div
                    style={{
                      background: "url(/assets/images/homepage-images/1.webp)",
                    }}
                  />
                </div>
                <div className="hexagonal two">
                  <div
                    style={{
                      background: "url(/assets/images/homepage-images/2.webp)",
                    }}
                  />
                </div>
                <div className="hexagonal three">
                  <div
                    style={{
                      background: "url(/assets/images/homepage-images/16.webp)",
                    }}
                  />
                </div>
                <div className="hexagonal four">
                  <div
                    style={{
                      background: "url(/assets/images/homepage-images/3.webp)",
                    }}
                  />
                </div>
                <div className="hexagonal five">
                  <div
                    style={{
                      background: "url(/assets/images/homepage-images/15.webp)",
                    }}
                  />
                </div>
                <div className="hexagonal six">
                  <div
                    style={{
                      background: "url(/assets/images/homepage-images/4.webp)",
                    }}
                  />
                </div>
                <div className="hexagonal seven">
                  <div
                    style={{
                      background: "url(/assets/images/homepage-images/17.webp)",
                    }}
                  />
                </div>
                <div className="hexagonal eight">
                  <div
                    style={{
                      background: "url(/assets/images/homepage-images/5.webp)",
                    }}
                  />
                </div>
                <div className="hexagonal nine">
                  <div
                    style={{
                      background: "url(/assets/images/homepage-images/6.webp)",
                    }}
                  />
                </div>
                <div className="hexagonal ten">
                  <div
                    style={{
                      background: "url(/assets/images/homepage-images/7.webp)",
                    }}
                  />
                </div>
                <div className="hexagonal eleven">
                  <div
                    style={{
                      background: "url(/assets/images/homepage-images/8.webp)",
                    }}
                  />
                </div>
                <div className="hexagonal twelve">
                  <div
                    style={{
                      background: "url(/assets/images/homepage-images/18.webp)",
                    }}
                  />
                </div>
                <div className="hexagonal thirteen">
                  <div
                    style={{
                      background: "url(/assets/images/homepage-images/9.webp)",
                    }}
                  />
                </div>
                <div className="hexagonal fourteen">
                  <div
                    style={{
                      background: "url(/assets/images/homepage-images/10.webp)",
                    }}
                  />
                </div>
                <div className="hexagonal fifteen">
                  <div
                    style={{
                      background: "url(/assets/images/homepage-images/14.webp)",
                    }}
                  />
                </div>
                <div className="hexagonal sixteen">
                  <div
                    style={{
                      background: "url(/assets/images/homepage-images/11.webp)",
                    }}
                  />
                </div>
                <div className="hexagonal seventeen">
                  <div
                    style={{
                      background: "url(/assets/images/homepage-images/12.webp)",
                    }}
                  />
                </div>
                <div className="hexagonal eighteen">
                  <div
                    style={{
                      background: "url(/assets/images/homepage-images/13.webp)",
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
          
        </div>

        <div className="slider-wrapper">
      <Slider {...settings}>
        {SlideList.map((slide, index) => (
          <div key={index} className="slide">
            <div
              className="slide-image"
              style={{
                backgroundImage: `url(${slide.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "400px", 
              }}
            >
              <div className="slide-content">
                <h2 className="title">{slide.title}</h2>
                <p>{slide.description}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
      </div> */}
       
     {/* Start Slider Area New  */}
     <div className="slider-wrapper">
      <Helmet pageTitle="BMI Cables" />
      <HeaderThree home homeLink="/" logo="symbol-light" color="color-black" />

      <Slider {...settings}>
        {SlideList.map((slide, index) => (
          <div key={index} className="slide">
            <div
              className="slide-image"
              style={{
                backgroundImage: `url(${slide.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                height: "600px", // Adjust the height as needed
              }}
            >
              <div className="slide-content">
                <h2 className="title1">{slide.title1}</h2>
                {slide.description && <p className="description">{slide.description}</p>}
                 {/* Button */}
                {slide.buttonText && (
                    <div className="slide-btn">
                    <a className="rn-button-style--2 btn-solid" href={slide.buttonLink}>
                        {slide.buttonText}
                    </a>
                    </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
    {/* End Slider Area   */}





      {/* Start About Us Area */}
      <div id="aboutUs" className="fix">
        <div className="rn-about-area ptb--120 bg_color--5">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/cables/insulatedcables4.jpg"
                      alt="About Images"
                      width="700"
                      height="500"
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner _text-center">
                    <div className="section-title">
                      <h2 className="title">About Us</h2>
                    </div>
                    <div className="row mt--30">
                      <div>
                        <ul className="list-style--1">
                          {namesItemOne.map((name, index) => {
                            return <li key={index}> {name}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="view-more-btn mt--30 mt_sm--20 text-center d-flex justify-content-center">
                          <Link
                            className="rn-button-style--2 btn-solid"
                            to="/about"
                          >
                            <span>More About Us</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Us Area */}



      {/* Start Portfolio Area */}
      <div id="products" className="fix">
        <div className="portfolio-area ptb--120 bg_color--1">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center _service-style--3 mb--30 mb_sm--0">
                    <h2 className="title">Product Category</h2>
                    <p className="text-black">
                      Experience seamless connectivity and uninterrupted power
                      with our high-quality cables.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row align-items-center justify-content-center">
                <PortfolioList
                  styevariation="text-center mt--40"
                  column="col-lg-3 col-md-6 col-sm-6 col-12 d-flex justify-content-center"
                  item="4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Area */}



      {/*/!* Start Blog Area *!/*/}
      <div id="focus" className="fix">
        <div className="rn-blog-area pt--100 bg_color--5 mb-dec--30">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-9 col-md-12 col-sm-12 col-12">
                <div className="section-title text-center justify-content-center">
                  <h2>Sectors</h2>
                  <p>
                    Power is paramount importance to life and to transmit and
                    distribute it in today's world, we need state-of-the-art
                    cables. BMI has been trying to address the growing need for
                    quality cables and has successfully supplied cable solutions
                    to various sectors.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt--60 mt_sm--40 mb--80 _justify-content-center">
              {PostList.map((value, i) => (
                <div className="col-lg-4 col-md-6 col-12" key={i}>
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      <img
                        className="w-100"
                        src={value.image}
                        alt="Blog Images"
                      />
                    </div>
                    <div className="content">
                      <p className="blogtype">{value.category}</p>
                      <h4 className="title">{value.title}</h4>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/*/!* End Blog Area *!/*/}



      {/* Start Why Choose Us Area */}
      <div id="whyChooseUs" className="fix _ptb--120 bg_color--1 mb--80">
        <div className="container ">
          <div className="row align-items-center justify-content-center">
            <div className="section-title text-center justify-content-center mt--5 mb--50">
              <h2>Why Choose Us</h2>
            </div>
          </div>

          <div className="row align-items-center">
            {/* Content Section */}
            <div className="col-lg-6">
              <div className="row mt--30">
                {whyChooseUsContent.map((item, index) => (
                  <div key={index} className="col-lg-12 mb--20">
                    <div className="why-choose-us-item d-flex align-items-start">
                      <div className="icon mr--15">{item.icon}</div>
                      <div>
                        <h4>{item.title}</h4>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Image Section */}
            <div className="col-lg-6">
              <div className="_thumbnail">
                <img
                  className="w-100"
                  src="/assets/images/cables/why-choose-banner.png"
                  alt="Why Choose Us"
                  width="700"
                  height="500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Why Choose Us Area */}

      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
      <Footer />
    </div>
  );
};

/*
 * EXPORTS
 */
export default PortfolioLanding;
